import React from "react";
import { Link } from "gatsby";

import Layout from "../layouts/en";
import Img_benefit_1 from "../images/home/benefit-1.svg";
import Img_benefit_2 from "../images/home/benefit-2.svg";
import Img_benefit_4 from "../images/home/benefit-4.svg";
import Img_benefit_5 from "../images/home/benefit-5.svg";
import Img_benefit_6 from "../images/home/benefit-6.svg";
import Img_benefit_8 from "../images/home/benefit-8.svg";
import SEO from "../components/seo";
import { useAuth0 } from "@auth0/auth0-react";
import { saveState } from "../state/utils/localStorage";
import { HomeBanner } from "../components/Carousel";

const IndexPage = (props) => {
  const { loginWithRedirect } = useAuth0();
  const isAuth0Enabled = process.env.GATSBY_ENABLE_AUTH0 === "true";

  return (
    <Layout location={props.location}>
      <SEO title="Home" />

      <HomeBanner lang="en" />

      <div className="home__benefit text--center">
        <div className="container container--500">
          <h1>Join and Get Benefits</h1>
          <p>
            From complimentary stays, the luxury of room upgrades to many
            exquisite dining experience, AYANA Rewards are designed to give you
            even greater rewards. See the benefit you can get with us
          </p>
        </div>
        <div className="container">
          <div className="home__benefit__list__wrapper">
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_1} />
              </div>
              <div className="home__benefit__list__title">
                Extra Bonus Points
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_2} />
              </div>
              <div className="home__benefit__list__title">
                Member Exclusive Room Discount
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_4} />
              </div>
              <div className="home__benefit__list__title">
                Complimentary Room Upgrade
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_5} />
              </div>
              <div className="home__benefit__list__title">Late Check Out</div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_6} />
              </div>
              <div className="home__benefit__list__title">
                Complimentary Gift on Birthday
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_8} />
              </div>
              <div className="home__benefit__list__title">Early Check In</div>
            </div>
          </div>
          <Link
            to="/member-benefits"
            key="home-member-benefits"
            className="home__benefit__action"
            data-hover="Learn more">
            <span>Learn more</span>
          </Link>
        </div>
      </div>

      <div className="home__earn">
        <div className="container">
          <h2 className="home__earn__title">Start earning your points</h2>
          <div className="home__earn__subtitle">
            AYANA Rewards are available for all hotels under the AYANA group.
            What are you waiting for? Get instant benefits when you join today.
          </div>
          <div className="home__earn__action">
            {isAuth0Enabled ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={() => {
                  const href = `/member`;
                  saveState("redirectUrl", href);
                  loginWithRedirect({
                    authorizationParams: {
                      ui_locales: "en",
                      screen_hint: "signup",
                      redirect_uri: `${window.location.origin}/callback`,
                    },
                    appState: { target: "redirectUrl" },
                  });
                }}
                key="home-signup"
                className="button button--cta">
                Join now
              </a>
            ) : (
              <Link
                to="/signup"
                key="home-signup"
                className="button button--cta">
                Join now
              </Link>
            )}
            {isAuth0Enabled ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={() => {
                  const href = `/member`;
                  saveState("redirectUrl", href);
                  loginWithRedirect({
                    authorizationParams: {
                      ui_locales: "en",
                      redirect_uri: `${window.location.origin}/callback`,
                    },
                    appState: { target: "redirectUrl" },
                  });
                }}
                key="home-login"
                className="link link--sm">
                Already a member? <b>Sign in</b>
              </a>
            ) : (
              <Link to="/login" key="home-login" className="link link--sm">
                Already a member? <b>Sign in</b>
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="home__points">
        <div className="container container--600 tablet--hide">
          <h1>Points of AYANA Rewards</h1>
          <p>Earn points on your purchase, and spend it for exciting rewards</p>
        </div>
        <div className="container">
          <div className="home__points__row">
            <div className="home__points__col">
              <Link
                to="/faq"
                key="faq-home"
                className="home__points__col__inner">
                <h2 className="home__points__col__title">
                  Earn <span className="mobile--only">&nbsp;Points</span>
                </h2>
                <div className="home__points__col__subtitle">
                  Learn how to get more points through your purchase
                </div>
                <div className="button button--cta mart--xl">Learn more</div>
              </Link>
            </div>
            <div className="home__points__col">
              <Link to="/reward" className="home__points__col__inner">
                <h2 className="home__points__col__title">
                  Redeem <span className="mobile--only">&nbsp;Points</span>
                </h2>
                <div className="home__points__col__subtitle">
                  Check out full list of what you can get with the points you
                  earned.
                </div>
                <div className="button button--cta mart--xl">Learn more</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
